@import '../base/settings';

header {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 1em;
}

.navigation-link.logo-desktop-link {
  display: none;
}

// .logo-container,
.logo-header {
  z-index: 1;
}

.light {
  .navigation-container {
    // background-color: $dark-bg-main;
    background-color: $light-bg-nav;
  }
  .navigation-link:hover,
  .navigation-link.active a .navigation-link-icon,
  .navigation-link.active a .navigation-link-text {
    color: #202022;
  }

  .hover:hover,
  .navigation-link.active {
    background-color: $teal-accent-color;
  }
}

.dark {
  .navigation-container {
    background-color: $dark-bg-nav;
  }

  .navigation-link {
    color: #f2ecec;
  }

  .navigation-link:hover,
  .navigation-link.active a .navigation-link-icon,
  .navigation-link.active a .navigation-link-text {
    color: #f2ecec;
  }

  .hover:hover,
  .navigation-link.active {
    background-color: $purple-accent-color;
  }
}

nav .language-box-desktop,
.logo-desktop {
  display: none;
}

.navigation-link-icon svg {
  width: 2rem;
  height: auto;
}

.navigation-link-text {
  font-size: 0.8rem;
}

.navigation {
  &-link {
    padding: 0.6rem 0.1rem;
  }

  &-language-container {
    display: flex;
    gap: 1rem;

    & > button {
      border: none;
      background-color: transparent;
    }
    .flag-box {
      width: 1.7rem;
      cursor: pointer;
    }
  }

  &-links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // grid-template-columns: repeat(4, 1fr);
    // grid-gap: 1rem;
  }

  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  &-contact-icons-box {
    display: none;
  }
}

.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

//////// DESKTOP NAVIGATION STYLES ////////////

@media (min-width: 48em) {
  .navigation {
    &-links {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 2%;
      right: 0;
      z-index: 9999;
      justify-content: center;
      align-items: center;
      // height: 100%;
    }
    &-container {
      grid-column: 2/3;
      position: static;
      // height: 100%;
    }

    &-contact-icons-box {
      display: flex;

      a {
        svg {
          width: 2rem;
          height: 2rem;
          fill: #007bbc;

          &:hover {
            fill: $logo-primary-fill;
          }
        }
      }
    }
  }

  // .contact-box-desktop {
  //   margin: 0.2rem;
  // }

  .header {
    display: none;
  }
  .language-box-desktop,
  .logo-desktop,
  .navigation-link.logo-desktop-link,
  nav .language-box-desktop {
    display: block;
  }

  .navigation-link.logo-desktop-link {
    display: flex;
    justify-self: center;
  }

  .header,
  main .parallax-container {
    padding: 0;
  }

  .light {
    .header {
      background-color: $light-bg-main;
    }
  }
  .dark {
    .header {
      background-color: $dark-bg-main;
    }
  }

  .logo-desktop {
    width: 6rem;
    // margin-bottom: 4rem;
  }

  nav .language-box-desktop {
    // padding-top: 8rem;
    display: flex;
    justify-content: center;
  }

  .navigation-links .second-link {
    grid-column: 1/2;
  }

  .contact-box-desktop {
    // padding: 3rem 0;
    padding: 1rem 0;
  }

  .navigation-language-container {
    // align-self: flex-end;
  }

  .navigation-contact-icons-box {
    gap: 0.2rem;
    a {
      svg {
        width: 2.5rem;
      }
    }
  }
}

@include mq(1050) {
  .navigation-contact-icons-box {
    gap: 1rem;
    a {
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  .contact-box-desktop {
    padding: 2rem 0;
  }

  nav .language-box-desktop {
    gap: 1.2rem;
    button {
      .flag-box {
        width: 2.5rem;
      }
    }
  }
}

// .hover:hover,
// .navigation-link.active {
//   background-color: $teal-accent-color;
// }
