@import '../base/settings';

.accordion {
  &-title {
    font-size: 1.3rem;
  }

  &-intro {
    margin-bottom: 1rem;
  }
  &-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.2rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    background-color: $light-bg-button;
    cursor: pointer;
  }

  &-icon {
    fill: #13385c;
    font-size: 1.6rem;
  }

  &-paragraph {
    padding-bottom: 1rem;
  }

  &-question {
    font-size: 1.5rem;
    padding: 1.5rem 0 1rem 0;
  }
}

.project-id-1 {
  .tab-content img:first-of-type {
    width: 6.25rem;
    margin: 0 auto;
    display: block;
    padding-bottom: 3rem;
  }

  .tab-content {
    img {
      padding-bottom: 1rem;
    }
    .img-box {
      padding-bottom: 2rem;
    }
  }
}

.tab-content {
  a,
  a:visited {
    text-align: center;
    display: block;
    padding: 2rem;
  }
}

.project-id-3 {
  .tab-content {
    padding-bottom: 2rem;
    a,
    a:visited {
      padding: 0.5rem;
    }
  }
}

.project-id-9 {
  .liveSite {
    display: none;
  }
  .tab-content {
    img {
      padding-bottom: 2rem;
      max-width: 650px;
      display: block;
      margin: 0 auto;
    }
  }
}

.project-id-14,
.project-id-8 {
  .tab-content {
    .content1 ~ img,
    .content2 ~ img,
    .content3 ~ img {
      width: 35rem;
      margin: 0 auto;
      display: block;
      padding: 3rem 0 3rem 0;
    }
  }
}

.sub-category-container {
  display: flex;
  justify-content: center;
  border-radius: 7rem;
  overflow: hidden;
  align-items: stretch;
  margin: 2rem 0;
}

.sub-category-button {
  border: none;
  padding: 0.9rem 0.1rem;
  background-color: transparent;
  cursor: pointer;
  flex: 1;
}

.sub-category-button.active {
  border-radius: 5rem;
}

.project-details {
  &-box {
    padding: 0.5rem;
  }
  &-title {
    text-align: center;
    padding: 2rem;
    font-size: clamp(2rem, 4vw, 5rem);
  }

  &-img {
    border-radius: 8px;
    padding: 0;
    display: block;
    margin: 0 auto;
  }

  &-cta-box {
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 1rem;
  }

  &-overview-title {
    font-size: 2rem;
    font-weight: 500;
    padding: 2rem 1rem 1rem 1rem;
  }

  &-overview-paragraph {
    padding-bottom: 1rem;
  }
}

h5 {
  font-size: 1.3rem;
  text-transform: uppercase;
  padding-bottom: 1.1rem;
}

/////////////////////////Light theme/////////////////////

.light {
  .accordion {
    &-tab {
      // background-color: $light-bg-button;
      background-color: #d5dbf2;
    }

    &-icon {
      fill: $light-text-main;
    }
  }

  .sub-category-container,
  .sub-category-button.active {
    background-color: #13385c99;
    background-color: #00326399;
  }

  .sub-category-button {
    color: #fff;
  }

  .tab-content {
    a {
      text-decoration: underline;
    }
    a,
    a:visited {
      color: $dark-bg-main;
    }

    a:hover {
      color: teal;
    }
  }
}

//Dark themex

.dark {
  .project-icon-wrapper,
  .project-details-overview-title,
  .project-details-title {
    color: $dark-heading-primary;
  }
  .accordion {
    &-tab {
      background-color: $dark-blue-accent-color;
    }

    &-icon {
      fill: $dark-bg-button;
    }

    &-title {
      color: $dark-bg-button;
    }
  }

  .sub-category-container,
  .sub-category-button.active {
    background-color: #115da999;
  }

  .sub-category-button {
    color: #fff;
  }

  .tab-content {
    a {
      text-decoration: underline;
    }
    a,
    a:visited {
      color: $teal-accent-color;
    }

    a:hover {
      color: $dark-text-main;
    }
  }

  .project-id-3,
  .project-id-4 {
    .project-details-img {
      border: black 10px solid;
    }
  }
}

.image-container {
  max-width: 325px;
  margin: 0 auto;
}
@include mq(500) {
  .image-container {
    max-width: 500px;
  }
}

@include mq(767) {
  .image-container {
    padding: 2rem;
    max-width: 900px;
  }

  .project-details-box {
    max-width: 800px;
    margin: 0 auto;
  }
}

@include mq(1200) {
  .image-container {
    max-width: 1200px;
  }
}
