@import '../base/settings';

@mixin primary-btn {
  // padding: 0.4em;
  padding: 1rem;
  border-radius: 7px;
  font-weight: 600;
  font-size: 1.4em;
}

.input-container input {
  width: 100%;
  border: none;
  font-size: 1rem;
}

.button-link{
  display: block;
  width: fit-content;
  margin: 0 auto;
}

//Desktop Navigation button
.navigation {
  &-desktop {
    .input-container {
      display: none;
    }
  }

  &-btn-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  &-link-box {
    place-items: center;
    gap: 1rem;
  }

  &-link-number {
    padding-right: 0.3em;
  }
}

.navigation .themeMobile {
  padding: 1.5em;
}
.header .navigation-btn-container .theme-toggle-desktop {
  padding: 1rem;
  border-radius: 50%;
}

//Dark theme
.dark {

  a.button-link.primary-button:hover,
  a.about-button.primary-button:hover{
     color: $dark-bg-button;
background-color: $purple-accent-color;
  }

  a.about-button.primary-button{
    color: $dark-blue-color-2;
  }
  a.button-link:visited {
    color: $light-text-main;
  }

  .primary-button {
    border: none;
    color: $light-text-main;
    background-color: $dark-bg-button;
    @include primary-btn;
  }

  .input-container input {
    background-color: $dark-bg-main;

    &::placeholder {
      color: $light-bg-nav;
    }
  }

  .primary-button:hover {
    background-color: $light-heading-secondary;

    a:hover,
    a.button-link:visited {
      color: $light-bg-nav;
    }

  
  }

  .project-button:hover {
    background-color: $light-bg-button;
  }
}

//Light theme

.light {
  .primary-button {
    border: none;
    background-color: $light-bg-button;
    a.button-link {
      color: $dark-bg-main;
    }
    @include primary-btn;
  }

  .input-container input {
    background-color: $light-bg-main;

    &::placeholder {
      color: $dark-bg-main;
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .primary-button:hover {
    background-color: #1da6bc;
    // border-color: #5d64ae;

    a:hover {
      color: #202022;
    }
  }

  .project-button:hover {
    background-color: #d5dbf2;
  }
}
.primary-button a.button-link {
  color: $light-heading-primary;
}

.hero {
  &-button {
    // display: block;
    margin: 0 auto;
    margin-top: 3rem;

    a {
      font-size: 1.3rem;
    }
  }
}

@include mq(760) {
  .hero-button {
    display: inline-block;
    margin: 0;
  }
}


.about {
  button.about-button {
    display: block;
    margin: 0 auto;
    width: 13rem;
    margin-top: 3rem;
  }
}