@import '../base/settings';

.hero-name,
.hero-role {
  font-size: clamp(20px, 8vw, 40px);
}

.hero {
  padding: 3rem 0 8.5rem 0;

  &-text-container {
    padding-top: 3rem;
  }

  &-description-box {
    margin-bottom: 4.5rem;
  }
  &-img {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    align-self: center;
    box-shadow: 3px 5px 14px rgba(0, 0, 0, 0.3);
  }

  &-greeting,
  &-name,
  &-subdescription {
    font-weight: 500;
  }

  &-role {
    font-weight: 400;
  }

  &-subdescription,
  &-description {
    display: inline;
  }

  &-role {
    margin-bottom: 2rem;
  }

  // &-button {
  //   // display: block;
  //   margin: 0 auto;
  //   margin-top: 3rem;

  //   a {
  //     font-size: 1.3rem;
  //   }
  // }
}

//Dark theme/ Light theme

.light .hero {
  &-greeting,
  &-subdescription {
    color: #333333;
  }
  &-role {
    color: #0b4fa8;
  }
}

.dark .hero {
  &-greeting,
  &-subdescription {
    color: $teal-accent-color;
  }
  &-name {
    color: $light-bg-button;
  }
}

@include mq(767) {
  .hero {
    display: grid;
    grid-template-columns: 1.2fr 2fr;

    &-img {
      margin-right: 3rem;
    }

    &-text-container {
      // max-width: 27rem;
      padding-left: 2rem;
      padding-top: initial;
    }
  }
}

@include mq(490) {
  .hero {
    // padding: 4rem 4rem 7.5rem 4rem;
    height: auto;
  }
}

@include mq(768) {
  .hero {
    padding: 10rem 0;

    &-description-box {
      padding-bottom: 2rem;
    }
  }
}

@include mq(900) {
  .hero {
    &-role {
      padding-bottom: 2rem;
    }
    &-description-box {
      padding-bottom: 4rem;
    }
  }
}
