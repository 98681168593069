// App Style Settings

// @use "../utilities/functions" as *;

// Colors

/* Main Background Color*/
$light-bg-main: #f2ecec;
$dark-bg-main: #202022;

/* Navigation Background Color*/
$light-bg-nav: #fff;
$dark-bg-nav: #000;

/* Button Background Color*/
// $light-bg-button: #d5dbf2;
$light-bg-button: #b5c2f5;
$dark-bg-button: #f2ecec;

//Project Background Color
$light-theme-project-bg: #c0cdede1;

/* Heading Color*/
$light-heading-primary: #13385c;
$dark-heading-primary: #ccd6f6;
$light-heading-secondary: #5464ae;
$dark-heading-primary: #f2ecec;

/* Logo Fill Color*/
$logo-primary-fill: #1da6bc;
$logo-secondary-fill: #5464ae;

/* Main Text Color*/
$light-text-main: #13385c;

$dark-text-main: #b0b6c8;

/* Accent Text Color*/
$teal-accent-color: #1da6bc;
$purple-accent-color: #5d64ae;
$dark-blue-accent-color: #073743;
$dark-blue-color-2: #112240;

// Fonts
$font: 'Roboto', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font;
  line-height: 1.3;
}

h1,
h2,
h3,
h4,
p {
  font-family: $font;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  font-size: 1.2em;
}

ul {
  list-style: none;
}

//Mixins for Media Queries
@function em($px, $bc: 16) {
  @return $px / $bc * 1em;
}

@function rem($px) {
  @return $px / 16 * 1rem;
}

// Media Query Mixin
@mixin mq($w) {
  $w: em($w);

  @media (min-width: #{$w}) {
    @content;
  }
}
