.hero {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hero.fade-up-active {
  opacity: 1;
  transform: translateY(0);
}
