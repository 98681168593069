@import '../base/settings';

.contact {
  // margin-top: 9.5rem;
  margin-top: 7.5rem;
  // padding-bottom: 9rem;

  &-icon {
    width: 3rem;
    height: 3rem;
    fill: #007bbc;
    &:hover {
      fill: $logo-primary-fill;
    }
  }
  &-icons-box {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 5.5rem;
  }

  &-text {
    padding-bottom: 2rem;
  }

  &-footer {
    // padding-bottom: 9rem;
  }

  &-icons-box + p {
    text-align: center;
    padding: 2rem;
  }
}

@include mq(600) {
  .contact-connect-section {
    max-width: 27rem;
  }
}

@include mq(768) {
  .contact {
    margin: 10rem 0;

    &-footer {
      // padding-bottom: 1rem;
      // p {
      //   padding-bottom: 1rem;
      // }
    }
    &-section {
      // display: flex;
      // justify-content: center;
    }

    &-icons-box {
      margin-top: 9rem;
    }
  }

  .contact .primary-button {
    margin-top: 2rem;
  }
}
