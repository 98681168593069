@import '../base/settings';

.dark {
  background-color: $dark-bg-main;
  color: $dark-text-main;

  .parallax-link-number {
    color: $teal-accent-color;
  }

  .header,
  .navigation {
    background-color: $dark-bg-nav;

    &-link-number {
      color: $teal-accent-color;
    }
    &-menu-dropdown {
      background-color: $dark-bg-nav;

      .line {
        // stroke: $light-bg-nav;
        stroke: $light-bg-main;
      }
    }

    &-separator {
      background-color: $dark-bg-main;
    }
  }
}

.dark {
  a:link,
  a:visited {
    color: $dark-heading-primary;
  }

  a:hover {
    color: $teal-accent-color;
  }

   a.primary-button:link, 
  a.primary-button:visited {
    color: #13385c;
  }
}
