@import '../base/settings';

.introduction {
  &.dark {
    background-color: #000;
  }

  &.light {
    background-color: #fff;
  }
}

.parallax-container {
  padding: 1rem 0.7rem;
}

.section-title {
  padding-bottom: 1.2em;
  font-weight: 500;
}

.parallax-link-number,
.title-text {
  font-size: 1.8rem;
}

.dark {
  .title-text {
    color: $light-bg-nav;
  }
}

.light {
  .title-text {
    color: $dark-bg-main;
    color: #3c3c3c;
  }
}

.section-container {
  padding: 0 0.2rem 3rem 0.2rem;
}

.contact-section {
  padding-bottom: 4rem;
}

@media (min-width: 48em) {
  .parallax-container {
    display: grid;
    grid-template-columns: 3fr 130px;
  }
}

@include mq(1050) {
  .parallax-container {
    grid-template-columns: 3fr 200px;
  }
}

@include mq(1200) {
  .parallax-container {
    grid-template-columns: 3fr 300px;
  }
}

@include mq(600) {
  .parallax-container {
    // padding: 1rem;
  }
}

@include mq(768) {
  .section-container {
    // padding: 4rem 4rem 7.5rem 4rem;
    max-width: 50rem;
    margin: 0 auto;
    padding: 0 2rem;
  }
}

.visually-hidden {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip-link:focus {
  outline: 2px solid blue;
}
