@import '../base/settings';

.light {
  color: $light-text-main;
  // background-color: $light-bg-main;
  // background-color: #d5d1e3;
  background-color: #e2e0ea;
  // background-color: #dfd2e8;

  .parallax-link-number {
    color: $purple-accent-color;
  }

  .header,
  .navigation {
    background-color: $light-bg-nav;

    &-link-number {
      color: $purple-accent-color;
    }
    &-menu-dropdown {
      background-color: $light-bg-nav;

      .line {
        stroke: $dark-bg-main;
      }
    }

    &-separator {
      background-color: $light-bg-button;
    }
  }
}

a:hover {
  color: $purple-accent-color;
}


.light {
  a:link,
  a:visited {
    color: $light-heading-primary;
  }
}
