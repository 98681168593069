@import '../base/settings';

.project-9 {
  .liveSite {
    display: none;
  }
}
.project {
  margin-top: 1.3rem;
  padding: 0 1rem;
  border-radius: 10px;

  &-filter-buttons {
    .project-button {
      margin-bottom: 1.4em;
    }
  }

  &-excerpt {
    flex-grow: 1;
  }

  &-filter-buttons {
    display: flex;
    gap: 0.6rem;
    // align-items: center;
    justify-content: center;
  }

  &-button {
    margin-bottom: 0.3rem;
    cursor: pointer;
  }

  &-title {
    font-size: 1.4rem;
    text-align: center;
    padding: 1.5rem 1rem;
  }

  &-intro-text {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 5rem;
    max-width: 40rem;
  }

  &-skills-box {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem 0;
  }

  &-skill {
    // padding: 0.4rem 0.2rem;
    // border-radius: 15px;
  }

  &-img {
    padding: 1rem;
    // height: 300px;
    // width: 100%;
    // object-fit: cover;
  }

  .project-cta-box {
    display: flex;
    gap: 0.5rem;
    padding: 1.5rem 0;
    justify-content: center;
  }

  &-icon-link {
    display: flex;
    flex-direction: column;
    place-items: center;
    cursor: pointer;
  }

  &-icon-box {
    // flex-grow: 0.5;
    flex-basis: 100%;
    transition: flex-grow 500ms ease-in-out, flex-basis 500ms ease-in-out;
  }

  &-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 0.5rem;

    svg {
      width: 1.1rem;
      height: 1.1rem;
      margin-bottom: 0.5rem;
    }

    span {
      font-size: 1.1rem;
    }
  }
}

.project-article:hover .project-icon-box.details {
  flex-grow: 2;
  flex-basis: 400px;
}

////////  LIGHT THEME  ////////////////

.light {
  .project {
    &-title {
      color: $dark-bg-main;
    }

    &-icon-wrapper:hover {
      svg {
        fill: $purple-accent-color;
      }
      .project-icon-title {
        color: $purple-accent-color;
      }
    }

    &-button.active {
      background-color: $teal-accent-color;
      color: $dark-bg-main;
    }

    &-article {
      .project-icon-box {
        background-color: $light-theme-project-bg;
        padding: 0.2rem;
        border-radius: 5px;
      }
    }
    &-icon-box.details a {
      color: #000b25;
    }
    &-article {
      background-color: $light-theme-project-bg;
    }

    //HOVER !!!
    &-article:hover {
      background-color: $dark-blue-accent-color;
      .project {
        &-title {
          color: $dark-heading-primary;
        }
        &-excerpt {
          color: $light-bg-button;
        }
        &-icon-link svg {
          fill: $dark-bg-main;
          fill: $light-bg-button;
        }

        &-icon-title {
          color: $light-bg-button;
        }
        &-skill {
          // color: $dark-blue-color-2;
          // background-color: $light-theme-project-bg;
          color: $light-theme-project-bg;
        }

        &-icon-box {
          background-color: $dark-blue-accent-color;
          padding: 0.2rem;
          border-radius: 5px;
        }
        &-icon-box.details {
          background-color: $logo-primary-fill;

          .project-icon-title {
            color: $dark-blue-color-2;
          }
          svg {
            fill: $dark-blue-color-2;
          }
        }
      }
    }

    &-skill {
      // color: #76b2bc;
      // background-color: $dark-blue-color-2;
      color: $dark-blue-color-2;
    }
  }
}

////////// DARK THEME ////////////////

.dark {
  .project {
    &-title {
      color: $teal-accent-color;
      color: #f2ecec;
    }

    &-button.active {
      background-color: $purple-accent-color;

      color: #dde6fc;
    }

    &-icon-wrapper:hover {
      svg {
        fill: $teal-accent-color;
      }
      .project-icon-title {
        color: $teal-accent-color;
      }
    }

    &-article {
      .project-icon-box {
        padding: 0.2rem;
        border-radius: 5px;
      }
    }

    &-excerpt,
    &-icon-box a {
      color: $light-bg-button;
    }

    &-icon-box.details a {
      color: #fff;
    }
    &-article {
      background-color: $dark-blue-accent-color;
    }

    //HOVER !!!

    &-article:hover {
      background-color: $light-theme-project-bg;
      .project {
        &-title {
          color: $dark-bg-main;
        }
        &-excerpt,
        &-icon-title {
          color: $dark-blue-color-2;
        }
        &-icon-link svg {
          fill: $dark-blue-color-2;
        }

        &-skill {
          // color: #76b2bc;
          // background-color: $dark-blue-color-2;
          color: $dark-blue-color-2;
          color: $dark-blue-accent-color;
        }

        &-icon-box {
          padding: 0.2rem;
          border-radius: 5px;
        }
        &-icon-box.details {
          background-color: $purple-accent-color;

          .project-icon-title {
            color: #dde6fc;
          }
          svg {
            fill: #dde6fc;
          }
        }
      }
    }

    &-skill {
      // color: $dark-blue-color-2;
      // background-color: $light-theme-project-bg;
      color: $light-theme-project-bg;
    }
  }
}

@include mq(490) {
  .project-section .section-title {
    // padding-left: 1.5rem;
  }
}
@include mq(640) {
  .project {
    // &-cards-box {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;
    //   gap: 1rem;
    // }

    &-article {
      flex: 0 0 calc(50% - 1rem); /* Each item takes 50% of the container width minus the gap */
    }
  }
}

@include mq(1050) {
  .project {
    &-cards-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }
  }
}

@include mq(767) {
  .project-section {
    // padding: 1rem;
  }

  .project-intro-text {
    // padding-left: 1.5rem;
    align-items: center;
    justify-content: center;
  }
}

@include mq(1300) {
  .project-section {
    // max-width: 1000px;
    // margin-left: auto;
    // margin-right: auto;
  }
}
@include mq(1050) {
  .project {
    &-article {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      display: grid;
      grid-template-rows: 110px 150px 180px 100px;
    }
  }
}

.light .project-article:hover {
  .project-icon-box.liveSite:hover,
  .project-icon-box.github:hover,
  .project-icon-box.design:hover {
    background-color: $light-theme-project-bg;

    svg {
      fill: $dark-blue-color-2;
    }
    .project-icon-title {
      color: $dark-blue-color-2;
    }
  }
}

.dark .project-article:hover {
  .project-icon-box.liveSite:hover,
  .project-icon-box.github:hover,
  .project-icon-box.design:hover {
    background-color: $dark-blue-accent-color;

    svg {
      fill: $light-bg-button;
    }
    .project-icon-title {
      color: $light-bg-button;
    }
  }
}
