.introduction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@mixin animations {
  fill: transparent;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
}

.introduction-logo {
  width: 13rem;
}

.st1 {
  stroke: #5d64ae;
  @include animations;
  animation: firstMAnimation 1.3s linear forwards;
}

@keyframes firstMAnimation {
  to {
    stroke-dashoffset: 0;
    fill: #5d64ae;
  }
}

.st2 {
  stroke: #1da6bc;
  @include animations;
  animation: secondMAnimation 1.3s linear forwards;
  animation-delay: 0.5s;
}

@keyframes secondMAnimation {
  to {
    stroke-dashoffset: 0;
    fill: #1da6bc;
  }
}

.st3 {
  fill: #1da6bc;
  stroke: #1da6bc;
  @include animations;
  animation: thirdMAnimation 1.3s linear forwards;
  animation-delay: 0.7s;
}

@keyframes thirdMAnimation {
  to {
    stroke-dashoffset: 0;
    fill: #1da6bc;
  }
}

.logo-header {
  width: 5rem;
  cursor: pointer;
}
