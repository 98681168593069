@import '../base/settings';

.about {
  padding: 0 0.2rem 7.5rem 0;
  &-text-container {
    margin-bottom: 4.5rem;
  }
  &-paragraph-2,
  &-paragraph-3 {
    padding-bottom: 1rem;
  }
  &-paragraph-4,
  &-paragraph-4-continued {
    display: inline;
  }
  &-paragraph-3 {
    padding-bottom: 1rem;
  }

  a.about-link {
    font-size: 1rem;
    text-decoration: underline;
  }

  // button.about-button {
  //   display: block;
  //   margin: 0 auto;
  //   width: 13rem;
  //   margin-top: 3rem;
  // }

  &-stack-title {
    font-weight: 500;
    padding: 2rem 0 1.5rem 0;
  }

  &-stack-icon {
    font-size: 2rem;
    margin-right: 1rem;
  }
}

a.about-link {
  &:hover {
    font-weight: 600;
  }
}

//Skills

.skills-container {
  padding-bottom: 10rem;
}

.skills-section {
  &-subtitle {
    font-size: 1.2rem;
  }
  &-list-items {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1rem 0 2rem 0;
  }

  &-list-item {
    padding: 0.4rem 0.5rem;
    border-radius: 15px;
  }
}

//Light Theme

.light {
  a.about-youtubeLink,
  a.about-facebookLink {
    color: $dark-bg-main;
  }

  .skills-section {
    &-list-item {
      color: $dark-blue-color-2;
      background-color: $light-theme-project-bg;
    }

    &-subtitle {
      color: #414d71;
    }
  }
}

//Dark theme
.dark {
  a.about-youtubeLink,
  a.about-facebookLink {
    color: $light-bg-button;
  }

  a.about-link {
    &:visited {
      // color: $dark-heading-primary;
      color: $dark-text-main;
    }

    &:hover {
      color: $teal-accent-color;
      font-weight: 500;
    }
  }

  .skills-section {
    &-list-item {
      color: $dark-blue-color-2;
      background-color: $light-theme-project-bg;
    }

    &-subtitle {
      color: $logo-primary-fill;
      color: #96bddb;
    }
  }
}

@include mq(600) {
  .aboutAndSkills-box {
    // padding: 0 2.5rem;
  }
}

@include mq(950) {
  .about {
    &-container {
      padding-top: 0;
    }

    button.about-button {
      margin: initial;
    }

    &-text-container {
      margin-bottom: 4rem;
    }

    &-stack-icons-container {
      max-width: 14.5rem;
    }
  }
  .tech-stack-container {
    max-width: 17rem;
  }
}

@include mq(768) {
  .about {
    padding-bottom: 10rem;
  }
}
